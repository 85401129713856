.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.center-last-card {
  margin-left: auto;
  margin-right: auto;
}


.carousel-caption-left {
  text-align: left;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 20px;
}

.d-flex .carousel-caption-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  object-fit: cover; 
}

input::placeholder {
  color: #c3a069 !important; 
}
textarea::placeholder {
  color: #c3a069 !important; 
}






.navbar {
  position: fixed !important;
  top: 0 !important;
  width: 100% !important;
  z-index: 1000 !important; 
}
.content {
  padding-top: 6rem;
}
.contact:hover{
  color: #0c5531 !important;
}

.green-background {
  background-color: green; 
  width: 50px; 
  height: 50px; 
  display: flex; 
  align-items: center; 
  justify-content: center; 
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  border-radius: 50%; 
  padding: 20px; 
}


.service-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  -webkit-transition: transform 0.3s ease, box-shadow 0.3s ease;
  -moz-transition: transform 0.3s ease, box-shadow 0.3s ease;
  -ms-transition: transform 0.3s ease, box-shadow 0.3s ease;
  -o-transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-img {
  height: 200px;
  object-fit: cover;
  transition: transform 0.4s ease;
  will-change: transform;
  -webkit-transition: transform 0.4s ease;
  -moz-transition: transform 0.4s ease;
  -ms-transition: transform 0.4s ease;
  -o-transition: transform 0.4s ease;
}

.card-body-fixed {
  background-color: #0c5531;
  color: #fff;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 150px;
  transition: background-color 0.3s ease;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  -ms-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
}

.card-body-fixed .card-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  transition: color 0.3s ease;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
}

.card-body-fixed .card-text {
  font-size: 0.95rem;
  margin-top: 10px;
  transition: color 0.3s ease;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
}


.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.5);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
}


.service-card:hover .service-img {
  transform: scale(1.2);
  transition: transform 0.4s ease;
  -webkit-transition: transform 0.4s ease;
  -moz-transition: transform 0.4s ease;
  -ms-transition: transform 0.4s ease;
  -o-transition: transform 0.4s ease;
}

.service-card:hover .card-body-fixed {
  background-color: #0a3d24;
}

.service-card:hover .card-title {
  color: #c3a069; 
}

.service-card:hover .card-text {
  color: white;
}

.custom-hr {
  border: none;
  height: 20px;
  width: 90%;
  background-color: #c3a069; 
  margin: auto; 
}


.navbar-nav .nav-link {
  color: #ffffff !important;
  transition: color 0.3s ease-in-out !important;
  -webkit-transition: color 0.3s ease-in-out !important;
  -moz-transition: color 0.3s ease-in-out !important;
  -ms-transition: color 0.3s ease-in-out !important;
  -o-transition: color 0.3s ease-in-out !important;
}


.navbar-nav .nav-link:hover {
  color: #c3a069 !important; 
}


.navbar-nav .nav-item.activee .nav-link {
  color: #c3a069 !important; 
  font-weight: bold !important; 
}




.about-item {
  width: 80%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  position: relative;
}

.about-item:nth-child(2) {
  transform: translateY(-20px); 
}

.about-item:nth-child(3) {
  transform: translateY(-40px); 
}
















@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
